import PubSub from 'pubsub-js';
import { userLoggedIn, userLoggedOut } from '../actions/authActions';

if (typeof window === 'object') {
  const Auth = require('j-toker/src/j-toker');
  Auth.configure({
    apiUrl: '',
    passwordResetSuccessUrl: () => `${window.location.origin}/change-password`,
    confirmationSuccessUrl: () => `${window.location.origin}/welcome`,
    authProviderPaths: {
      facebook: '/auth/facebook',
      google: '/auth/google_oauth2',
      apple: '/auth/apple'
    }
  });
}

let store = null;

PubSub.subscribe('auth', (msg, data) => {
  if (store) {
    if (msg === 'auth.validation.success') {
      store.dispatch(userLoggedIn(data));
    } else if (msg === 'auth.emailRegistration.success') {
      store.dispatch(userLoggedIn(data.data));
    } else if (msg === 'auth.signOut.success') {
      store.dispatch(userLoggedOut());
    } else if (msg === 'auth.validation.error') {
      store.dispatch(userLoggedOut());
    }
  } else {
    // TODO remove this hack. 
    // Since moving to Rails 6, sometimes PubSub sends its messages
    // before store exists, and the page fails to display.
    setTimeout(function(){
      if (store) {
        if (msg === 'auth.validation.success') {
          store.dispatch(userLoggedIn(data));
        } else if (msg === 'auth.emailRegistration.success') {
          store.dispatch(userLoggedIn(data.data));
        } else if (msg === 'auth.signOut.success') {
          store.dispatch(userLoggedOut());
        } else if (msg === 'auth.validation.error') {
          store.dispatch(userLoggedOut());
        }
      }
  }, 200);
  }
});

const authMiddleware = reduxStore => next => action => {
  if (!store) {
    store = reduxStore;
  }
  next(action);
};

export default authMiddleware;

// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import { Layout, AdminLayout, BoeingLayout } from './ParentLayout.imports-loadable';

import { getCityConfig } from '../selectors';

class ParentLayout extends Component {
  render() {
    const { cityConfig } = this.props;

    // Boeing subdomain
    if (cityConfig.city_name === "Seattle" && cityConfig.boeing) {
      return (
        <Switch>
          <Route path="/" component={BoeingLayout} />
        </Switch>
      );
    }

    return (
      <div>
        <Switch>
          <Route path="/admin" component={AdminLayout} />
          <Route exact path="/vendor/register" component={Layout} />
          <Route path="/vendor" component={AdminLayout} />
          <Route exact path="/catering/register" component={Layout} />
          <Route exact path="/catering" component={Layout} />
          <Route path="/catering" component={AdminLayout} />
          <Route exact path="/account" component={AdminLayout} />
          <Route exact path="/welcome" component={AdminLayout} />
          <Route exact path="/forgot-password" component={Layout} />
          <Route exact path="/change-password" component={AdminLayout} />
          <Route path="/" component={Layout} />
        </Switch>
      </div>
    )
  }
}

ParentLayout.propTypes = {
  children: PropTypes.object,
  clearError: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ParentLayout);